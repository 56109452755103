import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import Menu from '../Menu'
import Links from '../Links'
import profilePic from '../../pages/photo.jpg'
import './style.scss'
//import Adsense from '../GoogleAdsense'
import AdsenseSidebarTop from '../GoogleAdsenseSidebarTop'
import AdsenseSidebarBottom from '../GoogleAdsenseSidebarBottom'

import Search from '../Search'
import ClientOnly from '../../hook/ClientOnly.js'

import CategoryList from '../CategoryList'
import PeriodList from '../PeriodList'
import PopularList from '../PopularPages'
import LatestList from '../LatestList'

import TwitterTimeline from '../TwitterTimeline'

class Sidebar extends React.Component {

  render() {
    const { location } = this.props
    const {
      author,
      subtitle,
      copyright,
      menu,
    } = this.props.data.site.siteMetadata
    const isHomePage = get(location, 'pathname', '/') === '/'
   
    const pageContext = this.props.pageContext;

    /* eslint-disable jsx-a11y/img-redundant-alt */
    const authorBlock = (
      <div>
        <h1 className="author-header">管理人</h1>
        <h1 className="author-header_sub">めがね</h1>
        {/*}
        <Link to="/">
          <img
            src={profilePic}
            className="sidebar__author-photo"
            width="75"
            height="75"
            alt={author.name}
          />
        </Link>
        {isHomePage ? (
          <h1 className="sidebar__author-title">
            <Link className="sidebar__author-title-link" to="/">
              {author.name}
            </Link>
          </h1>
        ) : (
          <h2 className="sidebar__author-title">
            <Link className="sidebar__author-title-link" to="/">
              {author.name}
            </Link>
          </h2>
        )}
        */}
        {/*<hr className="sidebar__author-underline"/> */}
        <p className="sidebar__author-subtitle">{subtitle}</p>
      </div>
    )
    /* eslint-enable jsx-a11y/img-redundant-alt */

    return (
      <div className="sidebar">
        <div className="sidebar__inner">
          <AdsenseSidebarTop />
          {/* <div className="sidebar__author">{authorBlock}</div> */}
          <div>
            {/* following is google search box */}
            <ClientOnly>
              <Search />
            </ClientOnly>
            <PopularList />
            <TwitterTimeline />

            <div className="sidebar__author">{authorBlock}</div>
            <Menu data={menu} />
            {/* following is sns icons. Currently it's deactivated */}
            <Links data={author} />

            <CategoryList />
            <PeriodList />
            <LatestList />
          </div>
          
        </div>

        {/* following is the link for MOSHIMO affiliate that kinnikue added */}
        {/*
        <div className="rakuten_affiliate">
          <a href={`//af.moshimo.com/af/c/click?a_id=1766291&p_id=54&pc_id=54&pl_id=1252&guid=ON`} rel="nofollow">
            <img src="//image.moshimo.com/af-img/0032/000000001252.gif" width="234" height="60" style={{ border: 'none' }} />
          </a>
          <img src="//i.moshimo.com/af/i/impression?a_id=1766291&p_id=54&pc_id=54&pl_id=1252" width="1" height="1" style={{ border: 'none' }} />
        </div>
        */}

        {/* following is the link for Amazon ad that kinnikue added */}
        {/*
        <div className="amazon_affiliate">
          <a href={`//af.moshimo.com/af/c/click?a_id=1766312&p_id=170&pc_id=185&pl_id=4150&guid=ON`} rel="nofollow">
            <img src="//image.moshimo.com/af-img/0068/000000004150.gif" width="234" height="60" style={{ border: 'none' }} />
          </a>
          <img src="//i.moshimo.com/af/i/impression?a_id=1766312&p_id=170&pc_id=185&pl_id=4150" width="1" height="1" style={{ border: 'none' }} />
        </div>
        */}

        {/* following is the link for Axis ad that kinnikue added */}
        {/* <div className="axis_affiliate">
           <a href={`//af.moshimo.com/af/c/click?a_id=1789686&p_id=2502&pc_id=5511&pl_id=32378&guid=ON`} rel="nofollow">
             <img src="//image.moshimo.com/af-img/1979/000000032378.jpg" width="240" height="120" style={{ border: 'none' }} />
           </a>
           <img src="//i.moshimo.com/af/i/impression?a_id=1789686&p_id=2502&pc_id=5511&pl_id=32378" width="1" height="1" style={{ border: 'none' }} />
         </div>
    */}
        <div class="sidebar-ad-bottom">
          <AdsenseSidebarBottom />
        </div>
      </div>
      )
  }
}

export default Sidebar