import React from 'react'
import './style.scss'
import { TwitterTimelineEmbed } from 'react-twitter-embed';

export default function TwitterTimeline () {
    return (
        <div className="twitter-embed">
            <h1 className="twitter-header">ツイッター</h1>
            <TwitterTimelineEmbed
            sourceType="profile"
            screenName="KinnikuMeganeDe"
            options={{height: 400}}
            />
        </div>
    )
}