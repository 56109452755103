import React from 'react';
import AdSense from 'react-adsense';

const AdsenseSidebarTop = () => (
    <ins className="adinsidebartop">
        <AdSense.Google
            client='ca-pub-7870827999751510'
            slot='7495336395'
            style={{ display:'block', width: '300px', height: '600px'}}
            // auto fill with responsive ads
            format ='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
        />
    </ins>
);

export default AdsenseSidebarTop;