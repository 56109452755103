import React from 'react'
import Helmet from 'react-helmet'
import '../../assets/scss/init.scss'
import Header from "../Header"
import Footer from '../Footer'

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
       /* one div is added for footer */
      <div>
        <Header />
        <div className="layout">
          <Helmet defaultTitle="筋肉めがね" />
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
