import React from 'react'
import { Link } from "gatsby"
import './style.scss'
import '../../assets/fonts/fontello-771c82e0/css/fontello.css'
import { TwitterTimelineEmbed } from 'react-twitter-embed';

class Footer extends React.Component {

    render () {

        return (
            <div>
                {/* <div className="footer__created">
                    <div className="footer__created-profile">
                        <h4>About me</h4>
                        <hr />
                        <p>ドイツ現地採用サラリーマンです。Gatsbyブログのtips、資産運用、海外就職ノウハウ、書評や筋トレの事を書いております。</p>
                        <p><Link className="footer_created-profile-detail" to="/about">＞プロフィール詳細はこちら</Link></p>
                    </div>
                    <div className="footer__created-portfolio">
                        <h4>Portfolio</h4>
                        <hr />
                        <p><Link to="/">＞本サイト</Link></p>
                        <p className="footer__created-portfolio-second"><a href="https://yang-musikzimmer.com" target="_blank">＞ヴァイオリン奏者 梁洋子さんのホームページ</a></p>
                        <p className="footer__created-portfolio-second"><a href="https://webreelance.com" target="_blank">＞webreelance.com（英語のportfolio）</a></p>
                        <p className="footer__created-portfolio-second"><a href="https://webreelancejp.com" target="_blank">＞webreelancejp.com（日本語のportfolio）</a></p>
                    </div>
                    <div className="footer__created-twitter">
                        <h4>Twitter</h4>
                        <hr />
                        <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="KinnikuMeganeDe"
                        options={{height: 300}}
                        />
                    </div>
                </div> */}
                <div className="footer_last">
                    <Link className="footer_home" to="/">HOME</Link>
                    <p className="copyright__in-footer">Copyright - 筋肉めがね. 2019-2021 All Rights Reserved.</p>
                    <div className="footer_pagetop">
                        <a href="#"></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer